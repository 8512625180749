export const truncateText = (text, limit = 50, wordwrap = false) => {
    if(text) {
        let title = text;
        if(wordwrap && !limit) {
            title = title.match(/.{1,32}/g) || [];
            title = title.join(" ");
        } else if(wordwrap && limit > 50) {
            if (title.length >= limit) {
                title = title.match(/.{1,32}/g) || [];
                title = title.join(" ");
                title = title.substring(0, limit) + "..."
            }
        } else {
			if (title.length >= limit) title = title.substring(0, limit) + "..."
        }
        return title
    }
}